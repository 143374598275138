import React from 'react'

import Layout from '../components/layout'

const ContactPage = () => (
  <Layout bgClass="contact">

  <div className="textPageDetails contactPage">
      <h3 className="a-little-top-padding">Our Email Address</h3>
      <h2>
      <a href="mailto:contact@nakatomiprint.com">Contact@NakatomiPrint.com</a>
      </h2>

      <h3 className="a-little-top-padding">Our Earth Address</h3>
      <h2>
      Nakatomi Print Labs<br />2101 Airport Blvd, Suite 100<br />Austin, TX 78722 USA
      </h2>
  </div>

  </Layout>
)

export default ContactPage
